import { store } from "@/store";
import { currentUserApi } from "../../users/slice";
import { workspacesApi, setWorkspacesLoading } from "../slice";

/**
 * Retrieves the latest workspace for the current user.
 *
 * This function performs the following steps:
 * 1. Resets all data in the store.
 * 2. Fetches the current user's information.
 * 3. If the user has a last visited workspace, it fetches that specific workspace.
 * 4. If no last visited workspace is found, it fetches the first workspace in the list.
 *
 * @async
 * @function getLatestWorkspace
 * @returns {Promise<void>}
 * @throws Will throw an error if the API calls fail.
 */

export const getLatestWorkspace = async () => {
  store.dispatch(setWorkspacesLoading());

  const currentUser = await store.dispatch(
    currentUserApi.endpoints.getCurrentUser.initiate(undefined, { forceRefetch: true })
  );

  const lastVisitedWorkspaceId = currentUser.data?.lastVisitedWorkspace;

  if (currentUser.isSuccess && lastVisitedWorkspaceId) {
    await store.dispatch(
      workspacesApi.endpoints.getWorkspaceById.initiate(
        { workspaceId: lastVisitedWorkspaceId },
        { forceRefetch: true }
      )
    );
  } else {
    await store.dispatch(workspacesApi.endpoints.getFirstWorkspace.initiate());
  }
};
