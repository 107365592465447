import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Layout as AntLayout, Image, theme, Spin, Alert } from "antd";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { ReactNode } from "react";
import { useAppSelector } from "store/hooks";
import tinyColor from "tinycolor2";
import logo from "../../../../../assets/logo-long.svg";
import smallLogo from "../../../../../assets/small_logo.svg";
import peoplePattern from "../../../../../assets/people-pattern.svg";
import { useContrastingColors } from "../../../../ui-kit/core/contrasting-colors";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { SidebarMenuContextProvider } from "./context/provider";
import { useSidebarMenuContext } from "./context";
import useGetWorkspace from "logic/hooks/get-workspace";
import { BottomMenu } from "./components/bottom-menu";
import { TopMenu } from "./components/top-menu";
import { useImpersonateLogin } from "logic/hooks/impersonate-login";
import Breadcrumbs from "./components/breadcrumbs";

export const HEADER_LAYOUT_HEIGHT = 64;
export const BETA_UI_BANNER_HEIGHT = 26;
export const IMPERSONATING_USER_ALERT_HEIGHT = 96;
export const LAYOUT_CONTENT_ID = "layout-content";

type LayoutContentProps = {
  children: ReactNode;
  topBarActions?: ReactNode | null;
};

const LayoutContent: React.FC<LayoutContentProps> = ({ children, topBarActions }) => {
  // Theme token
  const { token } = theme.useToken();
  // Extra token
  const extraToken = useExtraToken();
  // Contrasting colors
  const contrastingColors = useContrastingColors();

  const workspacesLoading = useAppSelector((state) => state.workspaces.loading);

  const projectLoading = useAppSelector((state) => state.projects.loading);

  const { isImpersonating, sidebarMenuCollapsed, setSidebarMenuCollapsed } =
    useSidebarMenuContext();

  useGetWorkspace();
  useImpersonateLogin();

  const cssOverrides = `
.layout-menu .ant-menu-title-content {
  color: ${contrastingColors.get(token.colorPrimary).neutral};
}

.layout-menu .ant-menu.ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}

.ant-menu-item.ant-menu-item-only-child {
  margin: 0px;
}

.ant-menu-item-only-child > .ant-menu-title-content {
  height: 205x;
  line-height: 25px;
}

.layout-menu .ant-menu-item.ant-menu-item-selected {
  background-color: ${tinyColor(token.colorPrimary).brighten(10).toRgbString()};
}

.layout-sider .ant-layout-sider-children {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.layout-menu.ant-menu .ant-menu-item.login,
.layout-menu.ant-menu .ant-menu-item.login .ant-menu-title-content,
.layout-menu.ant-menu .ant-menu-item.login:hover,
.layout-menu.ant-menu
  .ant-menu-item.login:hover
  .ant-menu-title-content {
  background-color: ${token.colorBgBase};
  color: ${token.colorPrimaryText};
}

.menu-item:hover .menu-item-icon {
  visibility: visible;
}

.menu-wrapper {
  padding: 4px 8px;
  border-radius: 4px;

  &:hover,
  &.ant-dropdown-open {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.ant-breadcrumb ol {
  align-items: center;
}

.breadcrumbs-menu {
  .ant-dropdown-menu {
    max-height: 600px;
    overflow: auto;
  }

  .ant-dropdown-menu-item-selected {
   color: #000000 !important;
   background-color: #F5F5F5 !important;
 }
}

.menu-item .menu-item-icon.active {
  visibility: visible;
}

.menu-item-icon {
  visibility: hidden;
    
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
`;
  return (
    <>
      {!!(workspacesLoading || projectLoading) && <Spin spinning={true} fullscreen />}
      {isImpersonating && (
        <Alert
          message="You are currently impersonating another user."
          description="Please be very careful with the actions and make sure to log out when you are done."
          type="error"
          banner
        />
      )}
      <AntLayout
        style={{
          minHeight: 0,
          height: `calc(100vh - ${isImpersonating ? IMPERSONATING_USER_ALERT_HEIGHT : 0}px)`,
        }}
      >
        <style jsx global>
          {cssOverrides}
        </style>
        <AntLayout.Sider
          collapsed={sidebarMenuCollapsed}
          width={240} // added to fit workspace menu item
          className={`layout-sider`}
          style={{
            position: "relative",
            background: `url(${
              (peoplePattern as { src: string }).src
            }), linear-gradient(${extraToken.colorSideBarBg}, ${extraToken.colorSideBarBg})`,
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 4,
              right: 0,
              zIndex: 10,
            }}
          >
            <div
              style={{
                cursor: "pointer",
                padding: "8px 4px",
                borderRadius: "6px 0px 0px 6px",
                backgroundColor: "#F6F6FF",
              }}
              onClick={() => setSidebarMenuCollapsed()}
            >
              <AntIcon icon={sidebarMenuCollapsed ? faChevronRight : faChevronLeft} />
            </div>
          </div>
          <div>
            <Image
              style={{
                padding: token.paddingLG,
                paddingBottom: sidebarMenuCollapsed ? token.paddingMD : token.paddingXL,
              }}
              src={
                sidebarMenuCollapsed
                  ? (smallLogo as { src: string }).src
                  : (logo as { src: string }).src
              }
              alt="Brand"
              preview={false}
              width={sidebarMenuCollapsed ? "100%" : "85%"}
            />
            {/* Top Menu */}
            <TopMenu collapsed={sidebarMenuCollapsed} />
          </div>

          {/* Bottom Menu */}
          <BottomMenu collapsed={sidebarMenuCollapsed} />
        </AntLayout.Sider>

        {/* Content */}
        <AntLayout>
          <AntLayout.Header
            style={{
              backgroundColor: "#F6F6FF",
              height: HEADER_LAYOUT_HEIGHT,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingInline: 24,
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              zIndex: 10,
            }}
          >
            <Breadcrumbs />
            {topBarActions}
          </AntLayout.Header>
          <AntLayout.Content
            id={LAYOUT_CONTENT_ID}
            style={{ overflowY: "auto", height: "100%", maxHeight: "100%" }}
          >
            {children}
          </AntLayout.Content>
        </AntLayout>
      </AntLayout>
    </>
  );
};
type WithSidebarMenuLayoutProps = {
  children: ReactNode;
  ragEnabled?: boolean;
  surveysEnabled?: boolean;
  plansEnabled?: boolean;
  isImpersonating?: boolean;
  topBarActions?: ReactNode | null;
};

export const WithSidebarMenuLayout: React.FC<WithSidebarMenuLayoutProps> = ({
  children,
  ragEnabled,
  surveysEnabled,
  plansEnabled,
  isImpersonating,
  topBarActions,
}) => {
  return (
    <SidebarMenuContextProvider
      ragEnabled={ragEnabled}
      surveysEnabled={surveysEnabled}
      plansEnabled={plansEnabled}
      isImpersonating={isImpersonating}
    >
      <LayoutContent topBarActions={topBarActions}>{children}</LayoutContent>
    </SidebarMenuContextProvider>
  );
};
